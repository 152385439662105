<template>
  <div class="quantity-and-btn d-flex h-100">
    <div :class="{ '': productPage }" class="quantity-input d-flex align-items-center">
      <button class="step-down-btn p-0 bg-transparent text-dark m-0" @click="quantityDown">-</button>
      <input class="" type="number" min="1" :value="quantity" name="quantity" ref="inputQuantity" disabled />
      <button class="step-up-btn p-0 bg-transparent text-dark m-0" @click="quantityUp">+</button>
    </div>
    <div class="cart-btn">
      <button v-on:click="addToCart" v-bind:disabled="btnDisable" :style="buttonClass"
        class="btn btn-warning product-parameter-cart-btn">
        <font-awesome-icon v-if="!productPage" :icon="['fas', 'cart-shopping']" />
        <span v-else> Im Warenkorb </span>
      </button>
    </div>
  </div>
</template>

<script>
import config from "@storefront/core/lib/utils/config";

export default {
  name: "ProductAddtoCart",
  props: {
    product: Object,
    productConfig: Object,
    productPage: {
      type: Boolean,
      default: false,
    }
  },
  data: () => ({
    quantity: 1,
    cartPrices: {},
    cartItems: {}
  }),
  computed: {
    btnDisable() {
      if (this.product.__typename == "ConfigurableProduct") {
        const retval = this.$store.getters["product/getCurrentChildSku"];
        if (retval != null) {
          return false;
        } else {
          return true;
        }
      } else {
        return false;
      }
    },
    buttonClass() {
      if (this.btnDisable == true) {
        return "cursor: not-allowed;";
      } else {
        return "";
      }
    },
  },
  methods: {
    quantityUp() {
      this.quantity++;
    },
    quantityDown() {
      if (this.quantity > 1) this.quantity--;
    },
    async addToCart() {
      switch (this.product.__typename) {
        case "SimpleProduct": {
          const item =
            '{data: {sku: "' +
            this.product.sku +
            '", quantity:' +
            this.quantity +
            "}}  ";
          let retval = await this.$store.dispatch("cart/addToCart", {
            type: this.product.__typename,
            item: item,
          });
          if (retval == false) {
            const msg = {
              type: "danger",
              title: this.$t("shopping_basket"),
              text: this.$t("not_add_product"),
            };
            this.$store.dispatch("messages/sendMessage", { message: msg });
          } else {
            const text = this.$t("items_added_to_cart");
            const msg = {
              type: "success",
              title: this.$t("shopping_basket"),
              text: text.replace("{numberOf}", this.quantity),
            };
            this.$store.dispatch("messages/sendMessage", { message: msg });
            if (config.helloRetail && config.helloRetail.enabled) {
              this.HrTrackCart();
            }

            if (this.$gtm.enabled()) {
              window.dataLayer.push({
                event: "eec.add",
                ecommerce: {
                  add: {
                    products: [
                      {
                        name: this.product.name,
                        id: this.product.sku,
                        quantity: this.quantity,
                      },
                    ],
                  },
                },
              });
            }
            this.$bvModal.show("ProductCardModal");
          }
          break;
        }
        case "ConfigurableProduct": {
          const item =
            '{ parent_sku: "' +
            this.product.sku +
            '" data: {sku: "' +
            this.$store.getters["product/getCurrentChildSku"] +
            '", quantity:' +
            this.quantity +
            "}}  ";
          let retval = await this.$store.dispatch("cart/addToCart", {
            type: this.product.__typename,
            item: item,
          });
          if (retval == false) {
            const msg = {
              type: "danger",
              title: this.$t("add_to_cart"),
              text: this.$t("not_add_product"),
            };
            this.$store.dispatch("messages/sendMessage", { message: msg });
          } else {
            if (config.helloRetail && config.helloRetail.enabled) {
              this.HrTrackCart();
            }

            if (this.$gtm.enabled()) {
              window.dataLayer.push({
                event: "eec.add",
                ecommerce: {
                  add: {
                    products: [
                      {
                        name: this.product.name,
                        id: this.product.sku,
                        quantity: this.quantity,
                      },
                    ],
                  },
                },
              });
            }
            this.$bvModal.show("ProductCardModal");
          }
          break;
        }

        default:
          break;
      }
    },
    async HrTrackCart() {
      this.cartPrices = await this.$store.getters["cart/getCartPrices"];
      this.cartItems = await this.$store.getters["cart/getCartItems"];

      const hritems = [];
      this.cartItems.forEach((element) => {
        hritems.push(element.product.sku);
      });

      self.ADDWISH_PARTNER_NS.api.cart.setCart(
        {
          total: this.cartPrices?.grand_total.value,
          productNumbers: hritems,
        },
        function () {
          //Cart has been tracked for HR
        }
      );
    },
  },
};
</script>
